<template>
  <a-card style="background-color: #fff;">
    <div :class="advanced ? 'search' : null">
      <a-form layout="horizontal">
        <div :class="advanced ? null : 'fold'">
          <a-row>

            <a-col :md="5" :sm="20">
              <a-form-item label="处理状态">
                <a-select v-model="queryData.status" style="width: 100%" placeholder="请选择处理状态" @change="handleChange"
                  option-label-prop="label">
                  <a-select-option value="" label="全部">
                    全部
                  </a-select-option>
                  <a-select-option value="1" label="处理中">
                    处理中
                  </a-select-option>
                  <a-select-option value="2" label="处理成功">
                    处理成功
                  </a-select-option>
                  <a-select-option value="3" label="处理失败">
                    处理失败
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24" style="margin-left: 10px;">
              <a-form-item label="创建时间">
                <a-row>
                  <a-range-picker :ranges="{
      '今天': [moment(), moment()],
      '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      '当月': [moment().startOf('month'), moment().endOf('month')],
      '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }" @change="dateChange" />
                </a-row>
              </a-form-item>
            </a-col>

          </a-row>


        </div>

      </a-form>

    </div>


    <div v-if="hasPermission('EXPORT_TASK_LIST')">

      <standard-table :columns="columns" :dataSource="dataSource" :loading="tableLoading" :pagination="false">
        <div slot="description" slot-scope="{text}">{{ text }}</div>
        <div slot="createTime" slot-scope="{text}">{{ formatDate(text) }}</div>
        <div slot="effectiveTime" slot-scope="{text}">{{ formatDate(text) }}</div>
        <div slot="action" slot-scope="{text, record}">

          <a style="margin-right: 8px" v-if="record.status == 2 && hasPermission('DOWNLOAD_PROGRAMME')"
            @click="exportTask(record.id)">下载</a>
          <a style="margin-right: 8px; color: #ccc;" v-if="record.status != 2">下载</a>
          <a style="margin-right: 8px" @click="DownLoading(record.id)"
            v-if="hasPermission('DOWNLOAD_PROGRAMME_LOG')">下载记录</a>
        </div>
      </standard-table>
      <div class="page-box">
        <a-pagination :total="tabTotal" :current="tabCurrent" :pageSize="queryData.size" @change="tabPageChange" />
      </div>






      <!-- 选择成交状态 -->
      <a-modal title="查看下载记录" :visible="downVisible" centered width="45%" :footer="null" @cancel="downVisible = false">
        <standard-table :columns="downColumns" :dataSource="downList" :loading="downLoading" :pagination="false">
          <div slot="description" slot-scope="{text}">{{ text }}</div>
          <div slot="createTime" slot-scope="{text}">{{ formatDate(text) }}</div>
          <div slot="effectiveTime" slot-scope="{text}">{{ formatDate(text) }}</div>
          <div slot="action" slot-scope="{text, record}">

            <a style="margin-right: 8px" @click="exportTask(record.id)">下载</a>
            <a style="margin-right: 8px" @click="DownLoading(record.id)">下载记录</a>
          </div>

        </standard-table>
        <div class="page-box">
          <a-pagination :total="downTotal" :current="downCurrent" :pageSize="downParams.size"
            @change="downPageChange" />
        </div>
      </a-modal>
    </div>

    <div style="text-align: center;font-size: 20px;margin-top: 100px;" v-if="!hasPermission('EXPORT_TASK_LIST')">
      <a-empty :description="false" />
      无权限查看
    </div>
  </a-card>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
const columns = [
  {
    title: '创建时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' },
    align: 'center'
    // sorter: true
  },
  {
    title: '名称',
    dataIndex: 'name',
    align: 'center'

  },
  {
    title: '类型	',
    dataIndex: 'typeStr',
    align: 'center'

  },
  {
    title: '状态',
    dataIndex: 'statusStr',
    align: 'center'
  },
  {
    title: '创建人	',
    dataIndex: 'staffName',
    align: 'center'
  },
  {
    title: '失败原因',
    dataIndex: 'failReason',
    align: 'center'
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'center'
  }
]

export default {
  name: 'QueryList',
  components: { StandardTable },
  data() {
    return {
      downColumns: [

        {
          title: '下载ID',
          dataIndex: 'code',
          align: 'center'
        },
        {
          title: '文件地址',
          dataIndex: 'fileName',
          align: 'center'
        },
        {
          title: '下载人',
          dataIndex: 'staffName',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          // sorter: true
        },

      ],
      moment,
      customerData: [],
      userData: [],
      advanced: true,
      columns: columns,
      dataSource: [],
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },

      downList: [],


      downVisible: false,
      // 筛选
      queryData: {
        page: 0,
        size: 10,
        "dateMin": "",
        "dateMax": "",
        // "type": '',
        // "status": '',
        // "remarks": "",
        // "uid": ""
      },

      // 弹窗参数
      downParams: {
        "page": 0,
        "size": 10,
        "status": '',
        "dateMin": "",
        "dateMax": "",
        "type": '',
        "outCode": ""
      },

      tableLoading: false,
      downLoading: false,
      tabCurrent: 1,
      downCurrent: 1,
      downTotal: 0,
      tabTotal: 0

    }
  },
  authorize: {
    deleteRecord: 'delete'
  },
  mounted() {
    this.getData()

  },
  computed: {
    userPermissions() {
      return this.$store.state.account.user.authorityList;
    }
  },
  methods: {
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },
    handleChange(e) {
      if (e == '') {
        this.queryData.status = ''
      }
      this.getData()
    },
    // 选择日期
    dateChange(date, dateString) {
      this.queryData.dateMin = dateString[0].toString();
      this.queryData.dateMax = dateString[1].toString();
      this.getData()
    },
    // 获取列表 
    getData() {
      this.tableLoading = true;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/exporttask/list', 'post', this.queryData).then(res => {
        this.tableLoading = false;
        let data = res.data.data;
        if (res.data.code == 200) {
          this.dataSource = data.data;
          this.queryData.page = data.page;
          this.pagination.page = data.page;
          this.pagination.current = data.page + 1;
          this.pagination.pageSize = data.size;
          this.queryData.size = data.size;
          this.tabTotal = data.total;
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    // 查看下载记录
    DownLoading(id) {
      this.downParams.outCode = id;
      this.downLoading = true;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/downloadlog/list', 'post', this.downParams).then(res => {
        this.downLoading = false;
        if (res.data.code == 200) {
          let data = res.data.data;
          this.downVisible = true;
          this.downList = res.data.data.data;
          this.downParams.page = data.page;
          this.downCurrent = data.page + 1;
          this.downParams.size = data.size;
          this.downTotal = data.total;
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    tabPageChange(page) {
      this.queryData.page = page - 1;
      this.tabCurrent = page;
      this.getData()
    },
    downPageChange(page) {
      this.downParams.page = page - 1;
      this.downCurrent = page;
      this.DownLoading();
      // this.getData()
    },
    exportTask(id) {
      this.$message.loading('处理中')
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/export/task/path/' + id, 'get').then(res => {
        this.$message.destroy()
        if (res.data) {
          const link = document.createElement('a');
          link.href = res.data;
          link.download = 'excel.xlsx';
          link.style.display = 'none';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // window.open(res.data)
        } else {
          this.$message.error(res.data)
        }
      })
    },

    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      let result = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return result;
    },

    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },

  }
}
</script>

<style lang="less" scoped>
.search {
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}

::v-deep .page-content {
  padding: 0 !important;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}


.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}
</style>
